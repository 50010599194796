const IMAGE_STYLE = 'imageStyle';

const toCamelCase = prop => {
  return prop.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
};

const formatStyleStrToJsx = str =>
  str.split(';').reduce((acc, style) => {
    if (style.trim()) {
      const [property, value] = style.split(':');
      const camelCaseProperty = toCamelCase(property.trim());
      acc[camelCaseProperty] = value.trim();
    }
    return acc;
  }, {});

const filterStyleFromName = (str, styleName) => {
  if (!str || !styleName) return {};

  const regex = new RegExp(`${styleName}:"([^"]*)"`);
  const match = str.match(regex);

  if (!match) return {};

  const styleStrAsJsx = formatStyleStrToJsx(match[1]);

  return { [styleName]: styleStrAsJsx };
};

export const getStylesFromName = name => {
  const imageStyle = filterStyleFromName(name, IMAGE_STYLE);

  return { ...imageStyle };
};
